html {
  background-color: #282c34;
}

.App {
  text-align: center;
  color: white;
  background-color: #282c34;
  min-height: 100vh;
}

.row {
  max-width: 100vw;
}

.thumbnail {
  border-radius: 50%;
}

a {
  color: white !important;
  text-decoration: none !important;
}
a:hover {
  opacity: 0.5;
}

.channelname {
  font-size: 10px;
  text-decoration: underline;   
}

.videotitle {
  font-size: 18px;
  font-weight: 550;
  letter-spacing: 0.5px;
}

.videothumbnail {
  position: relative;
  color: white;
}

.videothumbnail > img {
  width: 250px ;
  border: 1px #010101 solid;
  border-radius: 5px;
}

.videoduration {
  position: absolute;
  padding: 0.5px 6px 0.5px 5px;
  font-size: 13px;
  font-weight: 600;
  top: 59px;
  right: 5px;
  background-color: black;
}

.videodate {
  font-size: 10px;
}

hr {
  background-color: #dedede;
}

.leftalign {
  text-align: left;
}

h1 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 44px !important;
}

.btn-tag {
  min-width: 85px;
  margin-left: 20px;
}

#header {
  background-color: #282c34;
  color: white; 
}

.profile-picture {
  height: 75px !important;
  width: 75px !important;
  padding: 0 0 0 0 !important;
  border-radius: 50px;
}

.subscribe-row {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  display: flex; 
  justify-content: space-between;
  align-items: center;

  margin-left: 35%;
  margin-right: 35%;
  width: 30%; 
}

.btn-subscribe {
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  min-width: 100px !important;
}

.btn-dark {
  background-color: #343A40!important;
}

.add-tag {
  border-radius: 25px !important;
  min-width: 30px;
  min-height: 30px;
}

.special {
  background-color: steelblue !important;
}